import React, { useContext } from 'react'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import { Container, Box, Flex } from '@chakra-ui/react'
import { spacing, breakpoints } from "../theme/themeAlopex"
import { H1 } from '../theme/Headings'
import ColorContext from '../context/ColorContext'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const OurWorkPost = ({ data, context }) => {
  const { color } = useContext(ColorContext)
  const { page, nextprev } = data
  const settings = {
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    dots: true,
    arrows: false,
    infinite: true,
    adaptiveHeight: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    fade: true,
  };

  // Get our next/prev data
  const npLinks = nextprev.edges.filter(x => x.node.id === page.id);

  return (
    <Layout>
      <SEO title={`${page.title} - Alopex`} />
      <Container pt={spacing.header} mb={spacing.elements} maxWidth={breakpoints.lg}>
        <H1 variant="pageHeader" color={color.alpha} textAlign="center">{page.title}</H1>
      </Container>
      <Container
        mb={spacing.section}
        maxWidth="72ch"
        sx={{
          'h2, h3': {
            color: color.alpha,
            marginTop: spacing.elements,
            marginBottom: spacing.icon
          },
          '.serviceLinks': {
            color: 'rgba(0, 0, 0, 0.1)',
            background: `-webkit-linear-gradient(right, ${color.alpha}, ${color.bravo})`,
            WebkitBackgroundClip: 'text',
            display: 'inline-block',
            a: {
              color: 'rgba(0, 0, 0, 0.2)',
              '&:hover': {
                color: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        }}
      >
        <Box
          color={color.alpha}
          sx={{
            'ul.slick-dots li button::before': {
              color: color.alpha
            }
          }}
        >
          {page.csImages != null &&
            <Slider {...settings} style={{margin: '0 auto 100px auto'}}>
              {page.csImages.map((img, i) => {
                return (
                  <img src={img.csImage.replace('/static', '')} alt={`work0${i}`} />
                )
              })}
            </Slider>
          }
        </Box>
        <ReactMarkdown remarkPlugins={[gfm]} children={data?.page?.content.replace('/static', '')} />
        {page.servicesLinks != null &&
          <Box
            as="p"
            mt={spacing.elements}
            className="serviceLinks"
          >
            Services:&nbsp;
            {page.servicesLinks.map((csl, i) => {
              return (
                <>
                  <AniLink paintDrip hex={color.alpha} to={`/services/${csl.serviceLink}`}>
                    {`${csl.serviceLinkTitle}`}
                  </AniLink>
                  {i < page.servicesLinks.length - 1 ? ', ' : ''}
                </>
              )
            })}
          </Box>
        }
        <Flex
          flexDirection={['column', null, 'row']}
          justifyContent="space-between"
          alignItems="center"
          mt={spacing.groups}
          sx={{
            a: {
              textDecoration: 'none'
            }
          }}
        >
          {npLinks.length > 0 && npLinks[0].previous != null &&
            <Box className="prevLink">
              <AniLink paintDrip hex={color.alpha} to={`/our-work/${npLinks[0].previous.slug}`}>
                &larr; {npLinks[0].previous.title}
              </AniLink>
            </Box>
          }
          <Box height={[spacing.icon, null, '0px']}></Box>
          {npLinks.length > 0 &&  npLinks[0].next != null &&
            <Box className="nextLink">
              <AniLink paintDrip hex={color.alpha} to={`/our-work/${npLinks[0].next.slug}`}>
                {npLinks[0].next.title} &rarr;
              </AniLink>
            </Box>
          }
        </Flex>
      </Container>
      <Footer mode="bg01" />
    </Layout>
  ) 
}

export const query = graphql`
  query ourWorkQuery($id: String!) {
    page: ourWorkJson(id: { eq: $id }) {
      title
      slug
      content
      id
      servicesLinks {
        serviceLinkTitle
        serviceLink
      }
      csImages {
        csImage
      }
    }
    nextprev: allOurWorkJson {
      edges {
        node {
          id
        }
        next {
          slug
          title
        }
        previous {
          slug
          title
        }
      }
    }
  }
`

export default OurWorkPost